import React, { useState, useCallback } from 'react';

import './FormSelect.scss';
import { labelize } from 'components';

type DefaultProps = {
  handleChange: (value: any) => any;
  label: string;
  initialValue?: string | number;
  optionMap: Record<any, any>;
};

type Props = DefaultProps &
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;

export const FormSelect: React.FC<Props> = ({
  handleChange,
  optionMap,
  label,
  initialValue,
  ...otherProps
}) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentValue = event.target.value;
      setSelectedValue(currentValue);
      handleChange(event);
    },
    [handleChange]
  );

  return (
    <div className="form-select">
      <label htmlFor={label}>{labelize(label)}</label>
      <select
        name={label}
        value={selectedValue}
        onChange={onValueChange}
        {...otherProps}
      >
        {Object.keys(optionMap).map((key) => (
          <option key={key} value={key}>
            {optionMap[key]}
          </option>
        ))}
      </select>
    </div>
  );
};
