import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { AppState } from 'app-state/reducers';
import { Section, selectDirectorySections } from 'app-state/directory';
import { MenuItem } from 'components';
import { DirectoryContainer } from './Directory.styles';

type Props = {
  sections: Section[];
};

export const DirectoryComponent: React.FC<Props> = ({ sections }) => (
  <DirectoryContainer>
    {sections.map(({ id, ...sectionProps }: Section) => (
      <MenuItem key={id} {...sectionProps} />
    ))}
  </DirectoryContainer>
);

const mapStateToProps = createStructuredSelector<AppState, Props>({
  sections: selectDirectorySections,
});

export const Directory = connect(mapStateToProps)(DirectoryComponent);
