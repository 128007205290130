import React from 'react';

import './PasswordInput.scss';
import { PasswordStrengthMeter } from 'components';
import { labelize } from 'components/form-input/FormInput';

type State = {
  isVisible: boolean;
};

type DefaultProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value: string;
  isVisible?: boolean;
  showMeter?: boolean;
};

type Props = DefaultProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export class PasswordInput extends React.Component<Props, State> {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    this.setState({ isVisible: !!this.props.isVisible });
  }

  toggleVisibility = () => this.setState({ isVisible: !this.state.isVisible });

  render() {
    const {
      label,
      value = '',
      showMeter = false,
      handleChange,
      ...otherProps
    } = this.props;

    const { isVisible } = this.state;

    return (
      <div className="group">
        <input
          name={label}
          type={isVisible ? 'text' : 'password'}
          className="password-input"
          onChange={handleChange}
          value={value}
          {...otherProps}
        />
        {label && (
          <label
            htmlFor={label}
            className={`password-input-label ${value.length ? 'shrink' : ''}`}
          >
            {labelize(label)}
          </label>
        )}
        {isVisible ? (
          <button
            onClick={this.toggleVisibility}
            className="visibility-btn"
            type="button"
          >
            ಠ_ಠ
          </button>
        ) : (
          <button
            onClick={this.toggleVisibility}
            className="visibility-btn"
            type="button"
          >
            ಹ_ಹ
          </button>
        )}
        {showMeter && <PasswordStrengthMeter password={value} />}
      </div>
    );
  }
}
