import React from 'react';
import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
} from './ErrorBoundary.styles';
import { Link } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
  state = {
    isError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return {
      isError: true,
    };
  }

  componentDidCatch(error: Error) {
    console.log(error);
  }

  render() {
    if (this.state.isError) {
      return (
        <ErrorImageOverlay>
          <ErrorImageContainer imageUrl="https://i.imgur.com/oCkEbrA.png" />
          <ErrorImageText>
            <h1>Something went wrong!</h1>
            <p>
              Please try again later or
              <Link to="/contact"> click here to contact us.</Link>
            </p>
          </ErrorImageText>
        </ErrorImageOverlay>
      );
    }

    return this.props.children;
  }
}
