import { createSelector } from 'reselect';
import { AppState } from '../store';

const selectUser = (state: AppState) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectIsSigningIn = createSelector(
  [selectUser],
  (user) => user.isSigningIn
);

export const selectIsSigningUp = createSelector(
  [selectUser],
  (user) => user.isSigningUp
);

export const selectIsGoogleSigningIn = createSelector(
  [selectUser],
  (user) => user.isGoogleSigningIn
);
