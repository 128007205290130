import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export type UserState = {
  currentUser: Object | null;
  error?: string;
  isSigningIn: boolean;
  isSigningUp: boolean;
  isGoogleSigningIn: boolean;
};

export type UserCredentials = {
  email: string;
  password: string;
};

export type SignUpCredentials = {
  displayName: string;
} & UserCredentials;

export type SignUpData = { user: firebase.User; additionalData: Object };

const setCurrentUser: CaseReducer<UserState, PayloadAction<Object>> = (
  state,
  action
) => {
  state.currentUser = action.payload;
};

const googleSignInStart: CaseReducer<UserState> = (state) => {
  state.isGoogleSigningIn = true;
};

const checkUserSession: CaseReducer<UserState> = () => {};

const emailSignInStart: CaseReducer<
  UserState,
  PayloadAction<UserCredentials>
> = (state) => {
  state.isSigningIn = true;
};

const signInSuccess: CaseReducer<UserState, PayloadAction<Object>> = (
  state,
  action
) => {
  state.currentUser = action.payload;
  state.isSigningIn = false;
  state.isGoogleSigningIn = false;
};

const signInFailure: CaseReducer<UserState, PayloadAction<string>> = (
  state,
  action
) => {
  state.error = action.payload;
  state.isSigningIn = false;
  state.isGoogleSigningIn = false;
};

const signOutStart: CaseReducer<UserState> = () => {};

const signOutSuccess: CaseReducer<UserState> = (state) => {
  state.currentUser = null;
  state.error = undefined;
};

const signOutFailure: CaseReducer<UserState, PayloadAction<string>> = (
  state,
  action
) => {
  state.error = action.payload;
};

const signUpStart: CaseReducer<UserState, PayloadAction<SignUpCredentials>> = (
  state
) => {
  state.isSigningUp = true;
};

const signUpSuccess: CaseReducer<UserState, PayloadAction<SignUpData>> = (
  state,
  action
) => {
  state.currentUser = action.payload.user;
  state.error = undefined;
  state.isSigningUp = false;
};

const signUpFailure: CaseReducer<UserState, PayloadAction<string>> = (
  state,
  action
) => {
  state.error = action.payload;
  state.isSigningUp = false;
};

const initialState: UserState = {
  currentUser: null,
  error: undefined,
  isSigningIn: false,
  isSigningUp: false,
  isGoogleSigningIn: false,
};

const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    checkUserSessionAction: checkUserSession,
    setCurrentUserAction: setCurrentUser,
    googleSignInStartAction: googleSignInStart,
    emailSignInStartAction: emailSignInStart,
    signInSuccessAction: signInSuccess,
    signInFailureAction: signInFailure,
    signOutStartAction: signOutStart,
    signOutSuccessAction: signOutSuccess,
    signOutFailureAction: signOutFailure,
    signUpStartAction: signUpStart,
    signUpSuccessAction: signUpSuccess,
    signUpFailureAction: signUpFailure,
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = counterSlice;

// Extract and export each action creator by name
export const {
  checkUserSessionAction,
  setCurrentUserAction,
  googleSignInStartAction,
  emailSignInStartAction,
  signInSuccessAction,
  signInFailureAction,
  signOutStartAction,
  signOutSuccessAction,
  signOutFailureAction,
  signUpStartAction,
  signUpSuccessAction,
  signUpFailureAction,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
