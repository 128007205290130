import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { AppState } from 'app-state';
import { toggleVisibilityAction, selectCartItemsCount } from 'app-state/cart';
import {
  CartContainer,
  ItemCountContainer,
  ShoppingIcon,
} from './ShoppingCartButton.styles';

type Props = {
  count?: number;
  toggleCartVisibility: () => void;
};

export const ShoppingCartButtonComponent: React.FC<Props> = ({
  count = 0,
  toggleCartVisibility,
}) => (
  <CartContainer onClick={toggleCartVisibility}>
    <ShoppingIcon />
    <ItemCountContainer>{count}</ItemCountContainer>
  </CartContainer>
);

const mapDispatchToProps = (dispatch: any) => ({
  toggleCartVisibility: () => dispatch(toggleVisibilityAction()),
});

const mapStateToProps = createStructuredSelector<
  AppState,
  Pick<Props, 'count'>
>({
  count: selectCartItemsCount,
});

export const ShoppingCartButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartButtonComponent);
