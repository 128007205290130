import styled from 'styled-components';

export const ProductDetailPageContainer = styled.div`
  padding: 20px 80px;

  @media screen and (max-width: 800px) {
    padding: 10px;
  }
`;

export const Arrow = styled.button`
  font-size: 18px;
  padding: 0 5px;
  cursor: pointer;
  background: transparent;
  border: none;
`;
