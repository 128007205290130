import styled from 'styled-components';

export const ProductGallery = styled.section`
  display: flex;
  flex-direction: column;
`;

export const MainImage = styled.div`
  align-self: flex-start;

  img {
    width: 100%;
  }
`;

export const ThumbGallery = styled.section`
  display: flex;
  max-width: 400px;
`;

export const ThumbGalleryList = styled.ul`
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

export const ThumbGalleryListItem = styled.li<{ selected: boolean }>`
  cursor: pointer;
  scroll-snap-align: start;
  height: 94px;
  min-width: 94px;
  margin: 0 5px;
  padding: 6px;
  display: flex;
  align-items: center;
  border: ${({ selected }) => (selected ? '1px solid #ccc' : 'none')};
  img {
    width: 100%;
  }
`;
