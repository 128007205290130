import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  CardElement,
  IbanElement,
  IdealBankElement,
} from '@stripe/react-stripe-js';
import {
  StripeIdealBankElementChangeEvent,
  StripeCardElementChangeEvent,
  StripeIbanElementChangeEvent,
} from '@stripe/stripe-js';

import './PaymentMethods.scss';
import { CustomButton, Segment } from 'components';
import { CountryCode } from 'components/country-select/countries';
import { getAvailablePaymentMethods } from './utils';

const createOptions = (fontSize: string = '14px', padding?: number) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#31325f',
        iconColor: '#666ee8',
        letterSpacing: '0.025em',
        fontWeight: '400',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#666ee8',
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export const CardForm: React.FC<{
  fontSize?: string;
  handleSubmit: (event: PaymentEvent) => void;
}> = ({ fontSize, handleSubmit }) => {
  const [validations, setValidations] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setValidations(event.error.message);
    } else {
      setValidations('');
    }

    setIsComplete(event.complete);
  };

  const handleClick = () => {
    if (isComplete) {
      handleSubmit({ type: 'card' });
    } else {
      alert('Your card details are incomplete.\n' + validations);
    }
  };

  return (
    <>
      <label>
        Card details
        <CardElement
          options={createOptions(fontSize)}
          onChange={handleChange}
        />
        {validations && <p className="validation-error">{validations}</p>}
      </label>
      <CustomButton onClick={handleClick}>PAY with Card</CustomButton>
    </>
  );
};

export const IbanForm: React.FC<{
  fontSize?: string;
  handleSubmit: (event: PaymentEvent) => void;
}> = ({ fontSize, handleSubmit }) => {
  const [validations, setValidations] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const handleChange = (event: StripeIbanElementChangeEvent) => {
    const { error, complete } = event;
    if (error) {
      setValidations(error.message);
    } else {
      setValidations('');
    }

    setIsComplete(complete);
  };

  const handleClick = () => {
    if (isComplete) {
      handleSubmit({ type: 'sepaDebit' });
    } else {
      alert('Your card details are incomplete.\n' + validations);
    }
  };

  return (
    <>
      <label>
        IBAN
        <IbanElement
          options={{ ...createOptions(fontSize), supportedCountries: ['SEPA'] }}
          onChange={handleChange}
        />
        {validations && <p className="validation-error">{validations}</p>}
      </label>
      <p>
        By providing your IBAN and confirming this payment, you’re authorizing
        CRWN Clothing and Stripe, our payment provider, to send instructions to
        your bank to debit your account. You’re entitled to a refund under the
        terms and conditions of your agreement with your bank.
      </p>
      <CustomButton onClick={handleClick}>
        PAY with SEPA Direct Debit
      </CustomButton>
    </>
  );
};

export const IdealBankForm: React.FC<{
  fontSize?: string;
  handleSubmit: (event: PaymentEvent) => void;
}> = ({ fontSize, handleSubmit }) => {
  const [bank, setBank] = useState<string>();

  const handleClick = () => {
    if (bank) {
      handleSubmit({ type: 'ideal' });
    } else {
      alert('select a bank first');
    }
  };

  return (
    <>
      <label>
        iDEAL Bank
        <IdealBankElement
          className="IdealBankElement"
          onChange={(event: StripeIdealBankElementChangeEvent) =>
            setBank(event.value)
          }
          options={createOptions(fontSize)}
        />
      </label>
      <p>You’ll be redirected to the banking site to complete your payment.</p>
      <CustomButton onClick={handleClick}>PAY with iDEAL</CustomButton>
    </>
  );
};

type Props = {
  country: CountryCode;
  onSubmit: (event: PaymentEvent) => void;
};

export type PaymentEvent = {
  type: 'card' | 'ideal' | 'sepaDebit';
};

export const PaymentSection: React.FC<Props> = ({ country, onSubmit }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');

  const availablePaymentMethods = useMemo(
    () => getAvailablePaymentMethods(country),
    [country]
  );

  useEffect(() => {
    setSelectedPaymentMethod('card');
  }, [country]);

  const handleSelected = useCallback(
    (method) => setSelectedPaymentMethod(method),
    [setSelectedPaymentMethod]
  );

  const paymentElements: Record<string, React.ReactNode> = {
    card: <CardForm handleSubmit={(event: PaymentEvent) => onSubmit(event)} />,
    ideal: (
      <IdealBankForm handleSubmit={(event: PaymentEvent) => onSubmit(event)} />
    ),
    sepaDebit: (
      <IbanForm handleSubmit={(event: PaymentEvent) => onSubmit(event)} />
    ),
  };

  return (
    <div className="payment-method">
      <Segment items={availablePaymentMethods} onSelected={handleSelected} />
      {paymentElements[selectedPaymentMethod]}
    </div>
  );
};
