import React from 'react';
import { connect } from 'react-redux';

import './CheckoutItem.scss';
import {
  CartItem,
  clearItemAction,
  removeItemAction,
  addItemAction,
} from 'app-state/cart';

type Props = {
  item: CartItem;
  clearItem: (item: CartItem) => void;
  removeItem: (item: CartItem) => void;
  addItem: (item: CartItem) => void;
};

export const CheckoutItemComponent: React.FC<Props> = ({
  item,
  clearItem,
  addItem,
  removeItem,
}) => {
  const { imageUrl, price, name, quantity } = item;
  return (
    <div className="checkout-item">
      <div className="image-container">
        <img src={imageUrl} alt={`item ${name}`} />
      </div>
      <span className="name">{name}</span>
      <span className="quantity">
        <button className="arrow" onClick={() => removeItem(item)}>
          &#10094;
        </button>
        <span className="value">{quantity}</span>
        <button className="arrow" onClick={() => addItem(item)}>
          &#10095;
        </button>
      </span>
      <span className="price">{price}</span>
      <button className="remove-button" onClick={() => clearItem(item)}>
        &#10005;
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  clearItem: (item: CartItem) => dispatch(clearItemAction(item)),
  removeItem: (item: CartItem) => dispatch(removeItemAction(item)),
  addItem: (item: CartItem) => dispatch(addItemAction(item)),
});

export const CheckoutItem = connect(
  null,
  mapDispatchToProps
)(CheckoutItemComponent);
