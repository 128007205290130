import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export type ShopSection = {
  id: string;
  title: string;
  routeName: string;
  items: ShopItem[];
};

export type ShopItem = {
  id: number;
  name: string;
  imageUrl: string;
  price: number;
};

export type ShopCollection = Record<string, ShopSection>;

export type ShopState = {
  collections?: ShopCollection;
  isFetching: boolean;
  error?: string;
};

const fetchCollectionsStart: CaseReducer<ShopState> = (state) => {
  state.isFetching = true;
};

const fetchCollectionsSuccess: CaseReducer<
  ShopState,
  PayloadAction<ShopCollection>
> = (state, action) => {
  state.isFetching = false;
  state.collections = action.payload;
};

const fetchCollectionsFailure: CaseReducer<ShopState, PayloadAction<string>> = (
  state,
  action
) => {
  state.isFetching = false;
  state.error = action.payload;
};

const updateCollections: CaseReducer<
  ShopState,
  PayloadAction<ShopCollection>
> = (state, action) => {
  state.collections = action.payload;
};

const initialState: ShopState = {
  collections: undefined,
  isFetching: false,
};

const counterSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    updateCollectionsAction: updateCollections,
    fetchCollectionsStartAction: fetchCollectionsStart,
    fetchCollectionsSuccessAction: fetchCollectionsSuccess,
    fetchCollectionsFailureAction: fetchCollectionsFailure,
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = counterSlice;

// Extract and export each action creator by name
export const {
  updateCollectionsAction,
  fetchCollectionsStartAction,
  fetchCollectionsSuccessAction,
  fetchCollectionsFailureAction,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
