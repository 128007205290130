import styled from 'styled-components';

import { SpinnerProps } from './Spinner';

export const SpinnerContainer = styled.div<SpinnerProps>`
  display: inline-block;
  width: ${({ size = '50px' }) => size};
  height: ${({ size = '50px' }) => size};
  border: 3px solid ${({ color = 'rgba(195, 195, 195, 0.6)' }) => color};
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerOverlay = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
