import React from 'react';
import zxcvbn from 'zxcvbn';

import './PasswordStrengthMeter.scss';

type Props = {
  password: string;
};

const SCORES = ['weak', 'moderate', 'strong', 'perfect!'];

export const PasswordStrengthMeter: React.FC<Props> = ({ password }) => {
  const result = zxcvbn(password);

  const { suggestions = '' } = result.feedback;

  return (
    <div className="meter" title={suggestions.toString()}>
      <span
        className="meter-bar"
        data-value={result.score}
        style={{
          width: `${(result.score * 100) / 4}%`,
        }}
      >
        {SCORES[result.score - 1]}
      </span>
    </div>
  );
};
