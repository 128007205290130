import { createSelector } from 'reselect';

import { AppState } from 'app-state/reducers';

const selectShop = (state: AppState) => state.shop;
export const selectCollections = createSelector(
  [selectShop],
  (shop) => shop.collections
);

export const selectIsCollectionFetching = createSelector(
  [selectShop],
  (shop) => shop.isFetching
);

export const selectIsCollectionsLoaded = createSelector(
  [selectShop],
  (shop) => !!shop.collections
);

export const selectCollectionsAsArray = createSelector(
  [selectCollections],
  (collections) =>
    collections ? Object.keys(collections).map((key) => collections[key]) : []
);

export const selectCollection = (collectionId: string) =>
  createSelector([selectCollections], (collections) =>
    collections ? collections[collectionId] : undefined
  );

export const selectProduct = (collectionId: string, productId: string) =>
  createSelector([selectCollection(collectionId)], (collection) =>
    collection?.items.length
      ? collection.items.find((item) => item.id === Number(productId))
      : undefined
  );
