import React from 'react';
import { DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ShoppingCartItem } from 'components';
import { CartItem } from 'app-state/cart';

import {
  CartDropdownContainer,
  EmptyMessageContainer,
  CartDropdownButton,
  CartItemsContainer,
  CartBackdropContainer,
} from './ShoppingCartDropdown.styles';

type DefaultProps = {
  cartItems: CartItem[];
  toggleCartVisibility: () => void;
};

type Props = DefaultProps & RouteComponentProps & DispatchProp;

export const ShoppingCartDropdown: React.FC<Props> = ({
  cartItems,
  history,
  toggleCartVisibility,
}) => {
  const goToCheckout = () => {
    toggleCartVisibility();
    history.push('/checkout');
  };

  return (
    <>
      <CartBackdropContainer onClick={() => toggleCartVisibility()} />
      <CartDropdownContainer>
        <CartItemsContainer>
          {cartItems.length ? (
            cartItems.map((item) => (
              <ShoppingCartItem key={item.id} item={item}></ShoppingCartItem>
            ))
          ) : (
            <EmptyMessageContainer>Your cart is empty.</EmptyMessageContainer>
          )}
        </CartItemsContainer>
        <CartDropdownButton onClick={goToCheckout}>
          GO TO CHECKOUT
        </CartDropdownButton>
      </CartDropdownContainer>
    </>
  );
};
