import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { CartState } from './cart';
import { UserState } from './user';
import { ShopState } from './shop';
import { DirectoryState } from './directory';
import { rootReducer as reducer } from './reducers';
import { rootSaga } from './rootSaga';

export type AppState = {
  user: UserState;
  cart: CartState;
  shop: ShopState;
  directory: DirectoryState;
};

const saga = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [saga],
});

saga.run(rootSaga);

export const persistor = persistStore(store);
