import styled, { css } from 'styled-components';

import { CustomButtonProps, CustomButton } from './CustomButton';

const buttonStyles = css`
  background-color: black;
  color: white;
  border: none;
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  border: 1px solid black;

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const googleSignInStyles = css`
  background-color: #4285f4;
  color: white;

  &:hover {
    background-color: #357ae8;
  }
`;

const getButtonStyles = (props: CustomButtonProps) => {
  if (props.isGoogleSignIn) {
    return googleSignInStyles;
  }

  return props.inverted ? invertedButtonStyles : buttonStyles;
};

export const CustomButtonContainer = styled.button<CustomButtonProps>`
  cursor: pointer;
  min-width: ${({ minWidth = '165px' }) => minWidth};
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;

  ${getButtonStyles}

  @media screen and (max-width: 800px) {
    min-width: ${({ minWidth = '120px' }) => minWidth};
  }
` as typeof CustomButton;
