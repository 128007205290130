import React, { useReducer } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  IbanElement,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeCardElement,
  StripeIbanElement,
} from '@stripe/stripe-js';
import faker from 'faker';

import './PaymentForm.scss';
import {
  FormInput,
  PaymentEvent,
  CountrySelect,
  PaymentSection,
} from 'components';
import { CountryCode } from 'components/country-select/countries';

type Props = {
  amount: number;
};

type FormState = {
  name: string;
  email: string;
  address: string;
  city: string;
  postalCode: string;
  country: CountryCode;
};

const initialState: FormState = {
  name: '',
  email: '',
  address: '',
  city: '',
  postalCode: '',
  country: 'NL',
};

const reducer = (
  state: FormState,
  { name, value }: { name: string; value: string }
) => ({
  ...state,
  [name]: value,
});

export const PaymentFormComponent: React.FC<Props> = ({ amount }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    dispatch({ name, value });
  };

  const { name, email, address, city, postalCode, country } = state;

  const stripe = useStripe();
  const elements = useElements();

  const getCustomerInfo = () => ({
    name,
    email,
    address: {
      city,
      postal_code: postalCode,
      country,
    },
  });

  const makeCardPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement) as StripeCardElement;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: getCustomerInfo(),
    });

    if (error) {
      alert('[error]' + JSON.stringify(error));
    } else {
      alert('[PaymentMethod] complete!' + JSON.stringify(paymentMethod));
    }
  };

  const makeIbanPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const ibanElement = elements.getElement(IbanElement) as StripeIbanElement;

    const { error } = await stripe.confirmSepaDebitSetup('{CLIENT_SECRET}', {
      payment_method: {
        sepa_debit: ibanElement,
        billing_details: getCustomerInfo(),
      },
    });

    if (error) {
      alert('[error]' + JSON.stringify(error));
    } else {
      alert('[PaymentMethod] complete!');
    }
  };

  const makeIdealPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    stripe
      ?.createSource({
        type: 'ideal',
        amount: amount * 100, // stripe uses cents form
        currency: 'eur',
        owner: getCustomerInfo(),
        statement_descriptor: 'Test payment',
        redirect: {
          return_url: `${window.location.href}-complete`,
        },
      })
      .then((payload: any) => {
        window.location.href = payload.source.redirect.url;
      });
  };

  const handleSubmit = async ({ type }: PaymentEvent) => {
    switch (type) {
      case 'ideal':
        await makeIdealPayment();
        return;
      case 'card':
        await makeCardPayment();
        return;
      case 'sepaDebit':
        await makeIbanPayment();
        return;
    }
  };

  const generateFakeData = () => {
    const { name, address, internet } = faker;
    dispatch({ name: 'name', value: `${name.firstName()} ${name.lastName()}` });
    dispatch({ name: 'email', value: internet.email() });
    dispatch({ name: 'address', value: `${address.streetAddress()}` });
    dispatch({ name: 'city', value: `${address.city()}` });
    dispatch({ name: 'postalCode', value: `${address.zipCode()}` });
  };

  // useEffect(() => {
  //   generateFakeData();
  // }, []);

  return (
    <div className="payment-form">
      <p>
        Complete or{' '}
        <span className="generate-data" onClick={() => generateFakeData()}>
          click here to generate
        </span>{' '}
        your shipping and payment details below
      </p>
      <form onSubmit={(e) => e.preventDefault()}>
        <h4>SHIPPING & BILLING INFORMATION</h4>
        <FormInput
          label="name"
          value={name}
          handleChange={handleChange}
          required
        />
        <FormInput
          label="email"
          autoComplete="email"
          value={email}
          handleChange={handleChange}
          type="email"
          required
        />
        <FormInput
          label="address"
          value={address}
          handleChange={handleChange}
          required
        />
        <FormInput
          label="city"
          value={city}
          handleChange={handleChange}
          required
        />
        <FormInput
          label="postalCode"
          value={postalCode}
          handleChange={handleChange}
          required
        />
        <CountrySelect initialValue={country} handleChange={handleChange} />
        <span>
          &#9991; Select another country to see different payment options.
        </span>
        <h4>PAYMENT INFORMATION</h4>
        <PaymentSection country={country} onSubmit={handleSubmit} />
      </form>
    </div>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_tqvJoH5EBHAi1T0KHZV4rjaZ004kaaY4pH');

export const PaymentForm: React.FC<Props> = ({ ...props }) => (
  <Elements stripe={stripePromise}>
    <PaymentFormComponent {...props} />
  </Elements>
);
