import React from 'react';
import { SpinnerContainer, SpinnerOverlay } from './Spinner.styles';

export type SpinnerProps = { size?: string; color?: string };

export const Spinner: React.FC<SpinnerProps> = (props) => (
  <SpinnerContainer {...props} />
);

export const CenteredSpinner: React.FC = () => (
  <SpinnerOverlay>
    <Spinner />
  </SpinnerOverlay>
);
