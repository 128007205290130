import React, { useState, useEffect, useCallback } from 'react';

import './Segment.scss';

type Props = {
  items: string[];
  onSelected: (item: string) => void;
};

export const Segment: React.FC<Props> = ({ items, onSelected }) => {
  const [checkedItem, setCheckedItem] = useState('');

  useEffect(() => {
    setCheckedItem(items[0]);
  }, [items]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedItem(event.target.value);
      onSelected(event.target.value);
    },
    [onSelected]
  );

  return (
    <nav className="segment">
      <ul>
        {items.map((item) => (
          <li className="segment-item" key={item}>
            <input
              id={item}
              type="radio"
              name="payment-method"
              value={item}
              checked={item === checkedItem}
              onChange={handleChange}
            />
            <label htmlFor={item}>{item}</label>
          </li>
        ))}
      </ul>
    </nav>
  );
};
