import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export type FirestoreRef = firebase.firestore.DocumentReference<
  firebase.firestore.DocumentData
>;

export type FirestoreQuerySnapshot = firebase.firestore.QuerySnapshot<
  firebase.firestore.DocumentData
>;

export type FirestoreDocumentSnapshot = firebase.firestore.DocumentSnapshot<
  firebase.firestore.DocumentData
>;

const config = {
  apiKey: 'AIzaSyCjeN5qTU08BJGuq8YQNuGr-kv1oKlHEsg',
  authDomain: 'blab-290ab.firebaseapp.com',
  databaseURL: 'https://blab-290ab.firebaseio.com',
  projectId: 'blab-290ab',
  storageBucket: 'blab-290ab.appspot.com',
  messagingSenderId: '541860112791',
  appId: '1:541860112791:web:0d1383c95cc5ce73483cd2',
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
});

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const createUserProfileDocument = async (
  userAuth: firebase.User | null,
  additionalData: Object = {}
) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { displayName, email, photoURL } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      throw new Error('Error creating user!' + error.message);
    }
  }

  return userRef;
};

// THIS IS TO BE USED ONLY FOR EXPORTING PURPOSES, NOT IN PRODUCTION.
// purpose: gets an array and makes a batch request to add that data to firestore.
// usage:
// addCollectionAndDocuments('collectionName', array);
export const DANGEROUSLY_addCollectionAndDocuments = async (
  collectionKey: string,
  objectsToAdd: any[],
  shouldIReallyDoThis: boolean
) => {
  if (shouldIReallyDoThis) {
    const collectionRef = firestore.collection(collectionKey);
    const batch = firestore.batch();
    objectsToAdd.forEach((obj: any) => {
      const newDocRef = collectionRef.doc();
      batch.set(newDocRef, obj);
    });
    console.log('Exporting data to firebase.. check your firebase console.');
    return await batch.commit();
  }
};

export default firebase;
