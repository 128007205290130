import styled from 'styled-components';

export const ProductContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const NameContainer = styled.h2`
  margin-top: 0;
`;

export const ShippingInfo = styled.p`
  font-style: italic;
  color: green;
`;

export const PriceContainer = styled.span`
  font-weight: bold;
  font-size: 24px;
`;

export const AvailableColorsContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  padding: 0;
`;

export const AvailableColor = styled.li<{ color: string }>`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-color: ${({ color }) => color};
`;
