import React, { useState } from 'react';

import './ContactForm.scss';
import { CustomButton, FormInput } from 'components';

export const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const { name, email, subject, message } = formData;

  const submit = () => console.log('submitted!');

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="contact-form">
      <h2 className="title">Write to us</h2>
      <span>Fill in the following fields to ask a question</span>
      <form onSubmit={submit}>
        <FormInput
          type="text"
          name="name"
          label="name"
          autoComplete="name"
          value={name}
          handleChange={onChange}
          required
        />
        <FormInput
          type="email"
          name="email"
          label="email"
          autoComplete="email"
          value={email}
          handleChange={onChange}
          required
        />
        <FormInput
          type="text"
          name="subject"
          label="subject"
          autoComplete="subject"
          value={subject}
          handleChange={onChange}
          required
        />
        <div className="contact-message">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            value={message}
            onChange={onChange}
            required
          />
        </div>

        <CustomButton type="submit">Send</CustomButton>
      </form>
    </div>
  );
};
