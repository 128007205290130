import React from 'react';

import { CustomButtonContainer } from './CustomButton.styled';
import { Spinner } from 'components';

type DefaultProps = {
  isGoogleSignIn?: boolean;
  inverted?: boolean;
  isLoading?: boolean;
  minWidth?: string;
};

export type CustomButtonProps = DefaultProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

export const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  isLoading,
  ...otherProps
}) => (
  <CustomButtonContainer {...otherProps}>
    {isLoading ? <Spinner size="25px" color="white" /> : children}
  </CustomButtonContainer>
);
