import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './SignUp.scss';
import { PasswordInput, CustomButton, FormInput } from 'components';
import {
  signUpStartAction,
  selectIsSigningUp,
  SignUpCredentials,
} from 'app-state/user';
import { AppState } from 'app-state';

type Props = {
  isMobile?: boolean;
  isSigningUp: boolean;
  signUp: (credentials: SignUpCredentials) => void;
};

export const SignUpComponent: React.FC<Props> = ({
  isMobile,
  signUp,
  isSigningUp,
}) => {
  const [credentials, setCredentials] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { displayName, email, password, confirmPassword } = credentials;

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert(`passwords don't match`);
      return;
    }

    try {
      signUp({ email, password, displayName });

      setCredentials({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
    } catch (error) {
      console.error(
        'there was an error registering your account.',
        error.message
      );
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="sign-up">
      {!isMobile && <h2 className="title">I do not have an account</h2>}
      <span>Sign up with your email and password</span>
      <form onSubmit={submit}>
        <FormInput
          type="text"
          name="displayName"
          label="name"
          autoComplete="name"
          value={displayName}
          handleChange={onChange}
          required
        />
        <FormInput
          type="email"
          name="email"
          label="email"
          autoComplete="email"
          value={email}
          handleChange={onChange}
          required
        />
        <PasswordInput
          showMeter
          label="password"
          autoComplete="new-password"
          value={password}
          handleChange={onChange}
          required
        />
        {!isMobile && (
          <PasswordInput
            autoComplete="new-password"
            label="confirmPassword"
            value={confirmPassword}
            handleChange={onChange}
            required
          />
        )}

        <CustomButton type="submit" isLoading={isSigningUp}>
          Sign up
        </CustomButton>
      </form>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  Pick<Props, 'isSigningUp'>
>({
  isSigningUp: selectIsSigningUp,
});

const mapDispatchToProps = (dispatch: any) => ({
  signUp: (credentials: SignUpCredentials) =>
    dispatch(signUpStartAction(credentials)),
});

export const SignUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpComponent);
