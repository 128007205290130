import React from 'react';

import {
  ShippingInfo,
  AvailableColor,
  ProductContainer,
  PriceContainer,
  NameContainer,
  AvailableColorsContainer,
} from './Product.styles';
import { ShopItem } from 'app-state/shop';
import { CustomButton, ImageGallery } from 'components';
import { connect } from 'react-redux';
import { addItemAction } from 'app-state/cart';

type Props = { product: ShopItem; addItem: (item: ShopItem) => void };

export const ProductComponent: React.FC<Props> = ({ product, addItem }) => {
  const images = [
    'samples/ecommerce/accessories-bag',
    'samples/ecommerce/leather-bag-gray',
    'samples/ecommerce/analog-classic',
  ];

  const availableColors = ['brown', 'blue'];

  return (
    <ProductContainer>
      <ImageGallery images={images} />
      <section>
        <NameContainer>{product.name}</NameContainer>
        <PriceContainer>€ {product?.price?.toFixed(2)}</PriceContainer>
        <p>Available colors</p>
        <AvailableColorsContainer>
          {availableColors.map((color) => (
            <AvailableColor color={color} />
          ))}
        </AvailableColorsContainer>
        <CustomButton onClick={() => addItem(product)}>
          Add to cart
        </CustomButton>
        <ShippingInfo>Free shipping</ShippingInfo>
        <p>30 days return policy</p>
      </section>
    </ProductContainer>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  addItem: (item: ShopItem) => dispatch(addItemAction(item)),
});

export const Product = connect(null, mapDispatchToProps)(ProductComponent);
