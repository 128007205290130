import React, { useState, useCallback } from 'react';

import './CountrySelect.scss';
import { COUNTRIES, CountryCode } from './countries';
import { FormSelect } from 'components';

type DefaultProps = {
  initialValue?: CountryCode;
  handleChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
};

type Props = DefaultProps &
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;

export const CountrySelect: React.FC<Props> = ({
  handleChange,
  initialValue = 'US',
  ...otherProps
}) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    initialValue
  );

  const onCountryChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentCountry = event.target.value as CountryCode;
      setSelectedCountry(currentCountry);
      handleChange(event);
    },
    [handleChange]
  );

  return (
    <div
      id="country"
      className={`country country--${selectedCountry.toLowerCase()}`}
    >
      <FormSelect
        label="country"
        name="country"
        optionMap={COUNTRIES}
        initialValue={selectedCountry}
        handleChange={onCountryChange}
        {...otherProps}
      />
    </div>
  );
};
