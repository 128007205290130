import { ShopSection, ShopCollection } from 'app-state/shop';

export const convertShopCollection = (
  collections: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): ShopCollection => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, items } = doc.data();

    return {
      id: doc.id,
      routeName: encodeURI(title),
      title,
      items,
    };
  });

  return transformedCollection.reduce(
    (accumulator: ShopCollection, collection: ShopSection) => {
      const key = collection.title.toLowerCase();
      accumulator[key] = collection;
      return accumulator;
    },
    {}
  );
};
