import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { reducer as userReducer, UserState } from './user';
import { reducer as cartReducer, CartState } from './cart';
import { reducer as directoryReducer, DirectoryState } from './directory';
import { reducer as shopReducer, ShopState } from './shop';

export type AppState = {
  user: UserState;
  cart: CartState;
  directory: DirectoryState;
  shop: ShopState;
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart'],
};

const combinedReducers = combineReducers({
  user: userReducer,
  cart: cartReducer,
  directory: directoryReducer,
  shop: shopReducer,
});

export const rootReducer = persistReducer(persistConfig, combinedReducers);
