import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  MenuItemContainer,
  BackgroundImage,
  MenuItemTitle,
  MenuItemSubtitle,
  MenuItemContent,
} from './MenuItem.styles';

type DefaultProps = {
  title: string;
  imageUrl: string;
  size?: string;
  linkUrl: string;
  match: string;
};

type Props = DefaultProps & RouteComponentProps;

const MenuItemComponent: React.FC<Props> = ({
  title,
  imageUrl,
  size,
  linkUrl,
  match,
  history,
}) => (
  <MenuItemContainer
    large={size === 'large'}
    onClick={() => history.push(match.url + linkUrl)}
  >
    <BackgroundImage className="background-image" imageUrl={imageUrl} />
    <MenuItemContent className="content">
      <MenuItemTitle>{title.toUpperCase()}</MenuItemTitle>
      <MenuItemSubtitle>SHOP NOW</MenuItemSubtitle>
    </MenuItemContent>
  </MenuItemContainer>
);

export const MenuItem = withRouter(MenuItemComponent);
