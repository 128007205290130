import { createSlice } from '@reduxjs/toolkit';

export type Section = {
  id: number;
  title: string;
  imageUrl: string;
  linkUrl: string;
  size?: string;
};

const SECTIONS: Section[] = [
  {
    title: 'hats',
    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
    id: 1,
    linkUrl: 'shop/hats',
  },
  {
    title: 'jackets',
    imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
    id: 2,
    linkUrl: 'shop/jackets',
  },
  {
    title: 'sneakers',
    imageUrl: 'https://i.ibb.co/0jqHpnp/sneakers.png',
    id: 3,
    linkUrl: 'shop/sneakers',
  },
  {
    title: 'women',
    imageUrl: 'https://i.ibb.co/GCCdy8t/womens.png',
    size: 'large',
    id: 4,
    linkUrl: 'shop/women',
  },
  {
    title: 'men',
    imageUrl: 'https://i.ibb.co/R70vBrQ/men.png',
    size: 'large',
    id: 5,
    linkUrl: 'shop/men',
  },
];

export type DirectoryState = {
  sections: Section[];
};

const initialState: DirectoryState = {
  sections: SECTIONS,
};

const counterSlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = counterSlice;

// Export the reducer, either as a default or named export
export default reducer;
