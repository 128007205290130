import React from 'react';

import './FormInput.scss';

type DefaultProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

type Props = DefaultProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

// labelName -> label name
export const labelize = (sentence: string) =>
  sentence
    .replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`)
    .replace(/^./, (match) => match.toLowerCase());

export const FormInput: React.FC<Props> = ({
  handleChange,
  label,
  ...otherProps
}) => {
  const value = (otherProps.value || '') as string;

  return (
    <div className="group">
      <input
        name={label}
        className="form-input"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label
          htmlFor={label}
          className={`form-input-label ${value.length ? 'shrink' : ''}`}
        >
          {labelize(label)}
        </label>
      )}
    </div>
  );
};
