import { CountryCode } from 'components/country-select/countries';

type Currency = 'eur' | 'usd';

type PaymentMethod = {
  name: 'Card' | 'iDEAL' | 'SEPA Direct Debit';
  flow: 'none' | 'redirect';
  countries?: CountryCode[];
  currencies?: Currency[];
};

export const getAvailablePaymentMethods = (country: CountryCode) => {
  const availableMethods = ['card']; // card is default for every country

  Object.keys(paymentMethods).forEach((key) => {
    const method = paymentMethods[key];
    if (method.countries && method.countries.includes(country)) {
      availableMethods.push(key);
    }
  });

  return availableMethods;
};

const paymentMethods: Record<string, PaymentMethod> = {
  card: {
    name: 'Card',
    flow: 'none',
  },
  ideal: {
    name: 'iDEAL',
    flow: 'redirect',
    countries: ['NL'],
    currencies: ['eur'],
  },
  sepaDebit: {
    name: 'SEPA Direct Debit',
    flow: 'none',
    countries: [
      'FR',
      'DE',
      'ES',
      'BE',
      'NL',
      'LU',
      'IT',
      'PT',
      'AT',
      'IE',
      'FI',
    ],
    currencies: ['eur'],
  },
};

export const formatPrice = (amount: number, currency: string) => {
  const price = Number((amount / 100).toFixed(2));
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  return numberFormat.format(price);
};

export const getLabel = (
  paymentMethod: string,
  bankName: string,
  total: number,
  currency: string = 'eur'
) => {
  const amount = formatPrice(total, currency);
  const name = paymentMethods[paymentMethod].name;
  let label = `Pay ${amount}`;
  if (paymentMethod !== 'card') {
    label = `Pay ${amount} with ${name}`;
  }
  if (paymentMethod === 'sepa_debit' && bankName) {
    label = `Debit ${amount} from ${bankName}`;
  }

  return label;
};
