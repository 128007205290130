import React from 'react';

import './OrderSummary.scss';
import { CartItem } from 'app-state/cart';
import { ShoppingCartItem } from 'components/shopping-cart';

type Props = {
  subtotal: number;
  shippingPrice?: number;
  items: CartItem[];
};

export const PriceInfo: React.FC<{
  title: string;
  price: number;
  large?: boolean;
}> = ({ title, price, large }) => (
  <div className={`price-info ${large ? 'price-info--large' : ''}`}>
    <span className="price-info__title">{title}</span>
    <span className="price-info__price">
      {price <= 0 ? 'Free' : `$${price}`}
    </span>
  </div>
);

export const OrderSummary: React.FC<Props> = ({
  subtotal,
  shippingPrice = 40,
  items,
}) => (
  <aside className="order-summary">
    <h2>Order Summary</h2>
    <div className="checkout-orders">
      {items.map((item) => (
        <ShoppingCartItem key={item.id} item={item}></ShoppingCartItem>
      ))}
    </div>
    <PriceInfo title="Subtotal" price={subtotal} />
    <PriceInfo title="Shipping" price={shippingPrice} />
    <div className="demo-info">
      <h5>Demo in test mode</h5>
      <p>
        You can copy and paste the following test cards to trigger different
        scenarios:
      </p>
      <p>Default US card: 4242424242424242</p>
      <p>Authentication required: 4000002760003184</p>
    </div>
    <PriceInfo title="Total" large price={shippingPrice + subtotal} />
  </aside>
);
