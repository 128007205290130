import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  HeaderContainer,
  LogoContainer,
  OptionsContainer,
  OptionLink,
} from './Header.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ShoppingCartButton } from 'components';
import { AppState } from 'app-state';
import { selectCurrentUser, signOutStartAction } from 'app-state/user';
import { selectCartVisibility } from 'app-state/cart';
import { ShoppingCartDropdownContainer } from 'components/shopping-cart/dropdown/ShoppingCartDropdownContainer';

type Props = {
  currentUser: Object | null;
  isCartVisible: boolean;
  signOut: () => void;
};

export const HeaderComponent: React.FC<Props> = ({
  currentUser,
  isCartVisible,
  signOut,
}) => (
  <HeaderContainer data-testid="header">
    <LogoContainer to="/">
      <Logo className="logo"></Logo>
    </LogoContainer>
    <OptionsContainer>
      <OptionLink to="/shop">SHOP</OptionLink>
      <OptionLink to="/contact">CONTACT</OptionLink>
      {currentUser ? (
        <OptionLink as="button" onClick={signOut}>
          SIGN OUT
        </OptionLink>
      ) : (
        <OptionLink to="/auth">SIGN IN</OptionLink>
      )}

      <ShoppingCartButton />
    </OptionsContainer>

    {isCartVisible && <ShoppingCartDropdownContainer />}
  </HeaderContainer>
);

const mapStateToProps = createStructuredSelector<
  AppState,
  Pick<Props, 'currentUser' | 'isCartVisible'>
>({
  currentUser: selectCurrentUser,
  isCartVisible: selectCartVisibility,
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOutStartAction()),
});

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
