import React from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';

export const CollectionItemContainer = styled.div`
  width: 22vw;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  &:hover {
    .image {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }

  @media screen and (max-width: 500px) {
    width: 70vw !important;
  }

  @media screen and (max-width: 800px) {
    width: 40vw;

    &:hover {
      .image {
        opacity: unset;
      }
      button {
        opacity: unset;
      }
    }
  }
`;

export const AddButton = styled((props) => <CustomButton {...props} />)`
  width: 80%;
  opacity: 0.7;
  position: absolute;
  top: 255px;
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
    opacity: 0.9;
    min-width: unset !important;
    padding: 0 20px !important;
  }
`;

export const CollectionBackgroundImage = styled.div<{ imageUrl: string }>`
  cursor: pointer;
  width: 100%;
  height: 95%;
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const CollectionFooterContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
`;

export const NameContainer = styled.span`
  width: 90%;
  margin-bottom: 15px;
`;

export const PriceContainer = styled.span`
  width: 10%;
  text-align: right;
`;

// .collection-item {
//   width: 22vw;
//   display: flex;
//   flex-direction: column;
//   height: 350px;
//   align-items: center;
//   position: relative;

//   .image {
//     width: 100%;
//     height: 95%;
//     background-size: cover;
//     background-position: center;
//     margin-bottom: 5px;
//   }

//   .custom-button {
//     display: none;
//     width: 80%;
//     opacity: 0.7;
//     position: absolute;
//     top: 255px;
//   }

//   &:hover {
//     .image {
//       opacity: 0.8;
//     }

//     .custom-button {
//       opacity: 0.85;
//       display: flex;
//     }
//   }

//   .collection-footer {
//     width: 100%;
//     height: 5%;
//     display: flex;
//     justify-content: space-between;
//     font-size: 18px;

//     .name {
//       width: 90%;
//       margin-bottom: 15px;
//     }

//     .price {
//       width: 10%;
//     }
//   }
// }
