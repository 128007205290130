export type CountryCode =
  | 'AU'
  | 'AT'
  | 'BE'
  | 'BR'
  | 'CA'
  | 'CN'
  | 'DK'
  | 'FI'
  | 'FR'
  | 'DE'
  | 'HK'
  | 'IE'
  | 'IT'
  | 'JP'
  | 'LU'
  | 'MY'
  | 'MX'
  | 'NL'
  | 'NZ'
  | 'NO'
  | 'PT'
  | 'SG'
  | 'ES'
  | 'SE'
  | 'CH'
  | 'GB'
  | 'US';

export type Country =
  | 'Australia'
  | 'Austria'
  | 'Belgium'
  | 'Brazil'
  | 'Canada'
  | 'China'
  | 'Denmark'
  | 'Finland'
  | 'France'
  | 'Germany'
  | 'Hong Kong'
  | 'Ireland'
  | 'Italy'
  | 'Japan'
  | 'Luxembourg'
  | 'Malaysia'
  | 'Mexico'
  | 'Netherlands'
  | 'New Zealand'
  | 'Norway'
  | 'Portugal'
  | 'Singapore'
  | 'Spain'
  | 'Sweden'
  | 'Switzerland'
  | 'United Kingdom'
  | 'United States';

export const COUNTRIES: Record<CountryCode, Country> = {
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BR: 'Brazil',
  CA: 'Canada',
  CN: 'China',
  DK: 'Denmark',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  HK: 'Hong Kong',
  IE: 'Ireland',
  IT: 'Italy',
  JP: 'Japan',
  LU: 'Luxembourg',
  MY: 'Malaysia',
  MX: 'Mexico',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NO: 'Norway',
  PT: 'Portugal',
  SG: 'Singapore',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  GB: 'United Kingdom',
  US: 'United States',
};
