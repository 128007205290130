import React from 'react';
import { connect } from 'react-redux';

import {
  CollectionItemContainer,
  CollectionBackgroundImage,
  PriceContainer,
  NameContainer,
  CollectionFooterContainer,
  AddButton,
} from './CollectionItem.styles';
import { addItemAction } from 'app-state/cart';
import { ShopItem } from 'app-state/shop';

type Props = {
  item: ShopItem;
  addItem: (item: ShopItem) => void;
  onItemClick: (item: ShopItem) => void;
};

export const CollectionItemComponent: React.FC<Props> = ({
  item,
  addItem,
  onItemClick,
}) => {
  const { name, price, imageUrl } = item;

  return (
    <CollectionItemContainer>
      <CollectionBackgroundImage
        onClick={() => onItemClick(item)}
        imageUrl={imageUrl}
        className="image"
      />
      <CollectionFooterContainer>
        <NameContainer>{name}</NameContainer>
        <PriceContainer>${price}</PriceContainer>
      </CollectionFooterContainer>
      <AddButton inverted onClick={() => addItem(item)}>
        Add to cart
      </AddButton>
    </CollectionItemContainer>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  addItem: (item: ShopItem) => dispatch(addItemAction(item)),
});

export const CollectionItem = connect(
  null,
  mapDispatchToProps
)(CollectionItemComponent);
