import React from 'react';

import './CollectionList.scss';
import { CollectionItem } from 'components';
import { ShopItem, ShopSection } from 'app-state/shop';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Props = {
  collection?: ShopSection;
  preview?: boolean;
};

export const CollectionListComponent: React.FC<Props & RouteComponentProps> = ({
  collection,
  preview,
  history,
  match,
}) => {
  if (!collection) return <div>empty!</div>;

  const { items, title, routeName } = collection;
  const products = preview ? items.filter((item, index) => index < 4) : items;

  const goToDetailPage = (item: ShopItem) => {
    // this component could either be under /shop/category or just /shop
    let url = match.url.includes(routeName.toLowerCase())
      ? `${match.url}/${item.id}`
      : `${match.url}/${routeName.toLowerCase()}/${item.id}`;
    history.push(url);
  };

  return (
    <div className="collection-list">
      <h1 className="title">{title.toUpperCase()}</h1>
      <div className="collection-list-items">
        {products.map((item: ShopItem) => (
          <CollectionItem
            key={item.id}
            item={item}
            onItemClick={goToDetailPage}
          />
        ))}
      </div>
    </div>
  );
};

export const CollectionList = withRouter(CollectionListComponent);
