import React from 'react';
import Carousel, { ModalGateway, Modal } from 'react-images';

type ReactImage = { source: { regular: string } };
type ModalGalleryProps = {
  isOpen: boolean;
  currentIndex?: number;
  images: ReactImage[];
  onClose: () => void;
  onSwipeEnd?: (index: number) => void;
};

export const ModalGallery: React.FC<ModalGalleryProps> = ({
  isOpen,
  onClose,
  images,
  currentIndex = 0,
  onSwipeEnd = () => {},
}) => {
  return (
    <ModalGateway>
      {isOpen ? (
        <Modal onClose={onClose} allowFullscreen={false}>
          <Carousel
            views={images}
            currentIndex={currentIndex}
            trackProps={{ onViewChange: (view) => onSwipeEnd(view) }}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};
