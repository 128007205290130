import React from 'react';

import { CenteredSpinner } from 'components';

type WithSpinnerProps = {
  isLoading: boolean;
};

export const WithSpinner = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithSpinnerProps> => (props: WithSpinnerProps) => {
  const { isLoading, ...otherProps } = props;
  return isLoading ? <CenteredSpinner /> : <Component {...(otherProps as P)} />;
};
