import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Header, CenteredSpinner, ErrorBoundary } from 'components';
import { AppState } from 'app-state';
import { selectCurrentUser, checkUserSessionAction } from 'app-state/user';

const HomePage = lazy(() =>
  import('pages/home/HomePage').then((module) => ({ default: module.HomePage }))
);

const ShopPage = lazy(() =>
  import('pages/shop/ShopPage').then((module) => ({ default: module.ShopPage }))
);

const AuthPage = lazy(() =>
  import('pages/auth/AuthPage').then((module) => ({ default: module.AuthPage }))
);

const ContactPage = lazy(() =>
  import('pages/contact/ContactPage').then((module) => ({
    default: module.ContactPage,
  }))
);

const CheckoutPage = lazy(() =>
  import('pages/checkout/CheckoutPage').then((module) => ({
    default: module.CheckoutPage,
  }))
);

const PaymentPage = lazy(() =>
  import('pages/payment/PaymentPage').then((module) => ({
    default: module.PaymentPage,
  }))
);

const SigninMobilePage = lazy(() =>
  import('pages/auth-mobile/AuthMobilePage').then((module) => ({
    default: module.SigninMobilePage,
  }))
);

const SignupMobilePage = lazy(() =>
  import('pages/auth-mobile/AuthMobilePage').then((module) => ({
    default: module.SignupMobilePage,
  }))
);

const PaymentCompletePage = lazy(() =>
  import('pages/payment-complete/PaymentCompletePage').then((module) => ({
    default: module.PaymentCompletePage,
  }))
);

type Props = {
  checkUserSession: () => void;
  currentUser: Object | null;
};

const App: React.FC<Props> = ({ checkUserSession, currentUser }) => {
  const location = useLocation();

  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  const getAuthPage = () =>
    window.innerWidth >= 1024 ? <AuthPage /> : <SigninMobilePage />;

  const redirectAuth = () =>
    currentUser ? <Redirect to="/" /> : getAuthPage();

  return (
    <>
      <Header />
      <Switch>
        <ErrorBoundary key={location.pathname}>
          <Suspense fallback={<CenteredSpinner />}>
            <Route exact path="/" component={HomePage} />
            <Route path="/shop" component={ShopPage} />
            <Route path="/payment" component={PaymentPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/payment-complete" component={PaymentCompletePage} />
            <Route exact path="/checkout" component={CheckoutPage} />
            <Route path="/auth" render={redirectAuth} />
            <Route exact path="/signup-mobile" component={SignupMobilePage} />
          </Suspense>
        </ErrorBoundary>
      </Switch>
    </>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  Pick<Props, 'currentUser'>
>({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  checkUserSession: () => dispatch(checkUserSessionAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
