import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './SignIn.scss';
import { PasswordInput, CustomButton, FormInput } from 'components';
import {
  googleSignInStartAction,
  emailSignInStartAction,
  UserCredentials,
  selectIsSigningIn,
  selectIsGoogleSigningIn,
} from 'app-state/user';
import { AppState } from 'app-state';

type Props = {
  googleSignInStart: () => void;
  emailSignInStart: (credentials: UserCredentials) => void;
  isSigningIn: boolean;
  isGoogleSigningIn: boolean;
  isMobile?: boolean;
};

export const SignInComponent: React.FC<Props> = ({
  emailSignInStart,
  googleSignInStart,
  isGoogleSigningIn,
  isSigningIn,
  isMobile,
}) => {
  const [credentials, setCredentials] = useState({
    email: 'john@doe.com',
    password: '12341234',
  });
  const { email, password } = credentials;

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    emailSignInStart({ email, password });

    setCredentials({ email: '', password: '' });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="sign-in">
      {!isMobile && <h2 className="title">I already have an account</h2>}
      <span>Sign in with your email and password</span>
      <form onSubmit={submit}>
        <FormInput
          type="email"
          label="email"
          autoComplete="email"
          value={email}
          handleChange={onChange}
          required
        />
        <PasswordInput
          label="password"
          autoComplete="current-password"
          value={password}
          handleChange={onChange}
          required
        />
        <div className="buttons">
          <CustomButton isLoading={isSigningIn} type="submit">
            Sign in
          </CustomButton>
          <CustomButton
            type="button"
            onClick={googleSignInStart}
            isGoogleSignIn
            isLoading={isGoogleSigningIn}
            minWidth="165px"
          >
            Sign in with Google
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  Pick<Props, 'isSigningIn' | 'isGoogleSigningIn'>
>({
  isSigningIn: selectIsSigningIn,
  isGoogleSigningIn: selectIsGoogleSigningIn,
});

const mapDispatchToProps = (dispatch: any) => ({
  googleSignInStart: () => dispatch(googleSignInStartAction()),
  emailSignInStart: (credentials: UserCredentials) =>
    dispatch(emailSignInStartAction(credentials)),
});

export const SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInComponent);
