import { takeLatest, call, all, put } from 'redux-saga/effects';

import {
  fetchCollectionsStartAction,
  fetchCollectionsSuccessAction,
  fetchCollectionsFailureAction,
} from './shop';
import { firestore, FirestoreQuerySnapshot } from 'app-database';
import { convertShopCollection } from 'app-database/transformers';

export function* fetchCollections() {
  try {
    const collectionRef = firestore.collection('collections');
    const snapshot: FirestoreQuerySnapshot = yield collectionRef.get();

    const collectionsMap = yield call(convertShopCollection, snapshot);
    yield put(fetchCollectionsSuccessAction(collectionsMap));
  } catch (error) {
    yield put(fetchCollectionsFailureAction(error.message));
  }
}

// Watchers
export function* onFetchCollectionsStart() {
  yield takeLatest(fetchCollectionsStartAction, fetchCollections);
}

export function* shopSagas() {
  yield all([call(onFetchCollectionsStart)]);
}
