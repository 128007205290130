import { takeLatest, put, call, all } from 'redux-saga/effects';

import { signOutSuccessAction } from 'app-state/user';
import { clearCartAction } from './cart';

export function* clearCart() {
  yield put(clearCartAction());
}

// Watchers
export function* onSignoutSuccess() {
  yield takeLatest(signOutSuccessAction, clearCart);
}

export function* cartSagas() {
  yield all([call(onSignoutSuccess)]);
}
