import React, { useState } from 'react';
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react';

import {
  MainImage,
  ProductGallery,
  ThumbGallery,
  ThumbGalleryList,
  ThumbGalleryListItem,
} from './ImageGallery.styles';
import { Arrow } from 'pages/product-detail/ProductDetailPage.styles';
import { ModalGallery } from './ModalGallery';

const ImageTransformations = ({
  width,
  name,
}: {
  width: any;
  name: string;
}) => {
  return (
    <Image publicId={`${name}.jpg`}>
      <Transformation width={width} crop="scale" />
    </Image>
  );
};

type Props = {
  images: string[];
};

export const ImageGallery: React.FC<Props> = ({ images }) => {
  const [showDetail, setShowDetail] = useState(false);
  const toggleGalleryModal = () => setShowDetail(!showDetail);

  const modalGalleryImages = images.map((img) => ({
    source: {
      regular: 'https://res.cloudinary.com/clothing-store/image/upload/' + img,
    },
  }));

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const moveLeft = () =>
    setSelectedImageIndex(Math.max(selectedImageIndex - 1, 0));

  const moveRight = () =>
    setSelectedImageIndex(Math.min(selectedImageIndex + 1, images.length - 1));

  return (
    <CloudinaryContext cloudName="clothing-store">
      <ProductGallery>
        <MainImage onClick={() => toggleGalleryModal()}>
          <ImageTransformations name={images[selectedImageIndex]} width="600" />
        </MainImage>
        <ThumbGallery>
          <Arrow onClick={moveLeft}>&#10094;</Arrow>
          <ThumbGalleryList>
            {images.map((image, index) => (
              <ThumbGalleryListItem
                tabIndex={0}
                selected={index === selectedImageIndex}
                onClick={() => setSelectedImageIndex(index)}
                key={image + index}
              >
                <ImageTransformations name={images[index]} width="94" />
              </ThumbGalleryListItem>
            ))}
          </ThumbGalleryList>
          <Arrow onClick={moveRight}>&#10095;</Arrow>
        </ThumbGallery>
        <ModalGallery
          currentIndex={selectedImageIndex}
          onSwipeEnd={(index) => setSelectedImageIndex(index)}
          isOpen={showDetail}
          onClose={toggleGalleryModal}
          images={modalGalleryImages}
        />
      </ProductGallery>
    </CloudinaryContext>
  );
};
