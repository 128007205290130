import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { AppState } from 'app-state/reducers';
import { ShoppingCartDropdown } from './ShoppingCartDropdown';
import {
  CartItem,
  selectCartItems,
  toggleVisibilityAction,
} from 'app-state/cart';
import { withRouter } from 'react-router-dom';

type Props = {
  cartItems: CartItem[];
};

const mapStateToProps = createStructuredSelector<AppState, Props>({
  cartItems: selectCartItems,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleCartVisibility: () => dispatch(toggleVisibilityAction()),
});

export const ShoppingCartDropdownContainer = compose<React.FC>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShoppingCartDropdown);
