import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import './CollectionsOverview.scss';
import { CollectionList } from 'components';
import { selectCollectionsAsArray, ShopSection } from 'app-state/shop';
import { AppState } from 'app-state/reducers';
import { CustomButton } from 'components/custom-button/CustomButton';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Props = {
  collections: ShopSection[];
};

export const CollectionsOverviewComponent: React.FC<
  Props & RouteComponentProps
> = ({ collections, history, match }) => {
  const goToShopSection = (route: string) => {
    history.push(`${match.path}/${route.toLowerCase()}`);
  };

  return (
    <div className="collections-overview">
      {collections.map((collection) => (
        <div key={collection.id}>
          <CollectionList preview collection={collection} />
          <CustomButton onClick={() => goToShopSection(collection.routeName)}>
            See more
          </CustomButton>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<AppState, Props>({
  collections: selectCollectionsAsArray,
});

export const CollectionsOverview = compose(
  connect(mapStateToProps),
  withRouter
)(CollectionsOverviewComponent);
